<template lang="id">
    <div>
      <div class='main-loader' v-if='isLoading'></div>
      <div class="index-carousel">
        <b-carousel id="carousel-caption" controls indicators v-if="Banner && Banner.length">
          <b-carousel-slide v-for="(item, index) in Banner" :key="index" class="card-img-overlay img-responsive-sm" :img-src="item.image_url">
            <h3 class="text-white heading-mobile" style="font-family: 'Roboto', sans-serif; font-size: 30px;">Meet your new AI assistant for creating daily posts!</h3>
<h2 class="text-white heading-mobile" style="font-family: 'Roboto', sans-serif; font-size: 26px;">Find and share daily news memes on your social media.</h2>
<h2 class="text-white heading-mobile" style="font-family: 'Roboto', sans-serif; font-size: 26px;">Easily create memes, comic and realistic images with simple text prompts.</h2>
<h2 class="text-white heading-mobile" style="font-family: 'Roboto', sans-serif; font-size: 26px;"></h2>

            <b-button :to="{ name: 'feed-images'}" pill variant="outline-primary" class="custom-button">Try Now </b-button>
          </b-carousel-slide>
        </b-carousel>
      </div>
  
      <div class="home-products border-top">
        <b-container>
          <div v-if="FeaturedImages && FeaturedImages.length">
            <b-row>
              <b-col>
                <vue-horizontal class="horizontal" responsive>
                  <div class="item" v-for="(product, index) in FeaturedImages" :key="product.id">
                    <b-card class="product-card" no-body>
                      <b-link class="text-body">
                        <b-img fluid class="card-img-top home-products-img-object-fit" :src="product.imageUrl" />
                      </b-link>
                    </b-card>
                    <h4 class="card-title text-truncate mb-0">Prompt: {{product.prompt}}</h4>
                  </div>
                </vue-horizontal>
              </b-col>
            </b-row>
            <b-row class="mt-1 text-center">
              <b-col>
                <b-button :to="{ name: 'explore-images'}" pill variant="outline-primary" class="custom-button">Explore More</b-button>
              </b-col>
            </b-row>
          </div>
          <div class="pt-2 pb-2" v-else>
            <div class="mx-auto text-center">
              <h4 class="font-normal"></h4>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </template>
  
  <script>
  import {
    BCarousel,
    BCarouselSlide,
    BCard,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    BLink,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import VueHorizontal from "vue-horizontal";
  import { mapState, mapActions } from 'vuex';
  
  export default {
    components: {
      BCarousel,
      BCarouselSlide,
      BContainer,
      BRow,
      BCol,
      BCard,
      BButton,
      BImg,
      BLink,
      VueHorizontal,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isLoading: false,
      };
    },
    computed: {
      ...mapState('app', {
        Banner: state => state.banner,
        FeaturedImages: state => state.featured_images,
      }),
    },
    mounted() {
      this.loadFeaturedImagesFromStorage();
      this.getBannerDetails();
      this.fetchFeaturedItems();
    },
    methods: {
      ...mapActions('app', [
        'getBannerDetails',
        'fetchFeaturedItems',
        'loadFeaturedImagesFromStorage',
      ]),
    },
  };
  </script>

<style lang="scss" scoped>
.filterQuery {
    color: black(5, 5, 10);
    cursor: pointer;
}

.filterQueryColor {
    color: black(5, 5, 10);
    cursor: pointer;
}

.filterQueryColor:hover {
    color: yellow;
    cursor: pointer;
}

.filterQuery:hover {
    color: #36454F;
}

.style-square {
    display: inline-block;
    width: 40px;
    /* Adjust the width as needed */
    height: 15px;
    /* Adjust the height as needed */
    /* Adjust the background color as needed */
    margin-left: 10px;
    /* Adjust the spacing between the square and text as needed */
    margin-top: 15px;
    border: 1px solid black;
    background-color: #999696;
}

.heading-mobile {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Show only the first line */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 600px) { /* Adjust as needed for your breakpoint */
  .heading-mobile {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Show only the first line */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 640px) {
    div.item {
        width: calc((100% - (24px)) / 2);
    }
}

@media (min-width: 768px) {
    div.item {
        width: calc((100% - (2 * 24px)) / 3);
    }
}

@media (min-width: 1024px) {
    div.item {
        width: calc((100% - (3 * 24px)) / 4);
    }
}

@media (min-width: 1280px) {
    div.item {
        width: calc((100% - (3 * 24px)) / 4);
        /* width: calc((100% - (4 * 24px)) / 4); */
    }
}

@media (max-width: 600px) {
    .v-hl-svg {
        width: 30px !important;
        height: 30px !important;
    }
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.loader {
    color: #ffffff;
    /* Customize the color of the spinner */
}

.custom-button.btn {
    background-color: black !important;
    color: white !important;
    border-color: black !important;
    transition: all 0.3s ease; /* Smooth transition for hover and active states */
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.custom-button.btn:hover,
.custom-button.btn:focus {
    background-color: #333 !important; /* Slightly lighter black for hover effect */
    color: white !important; /* Ensure the text color stays white */
    border-color: #333 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}

.custom-button.btn:active {
    background-color: #222 !important; /* Even darker shade for active state */
    color: white !important; /* Ensure the text color stays white */
    border-color: #222 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* More pronounced shadow on active state */
    transform: scale(0.98); /* Slightly reduce size to indicate click */
}


.video-container {
    position: relative;
    text-align: center;
}

.text-body {
    position: relative;
}

.filterQuery:hover {
    color: blue;
}

.popover-content {
    //   display: none;
    position: absolute;
    //   top: 100%;
    bottom: 110px;
    left: 310px;
    width: 250px;
    background-color: #f9f9f9;
    border: 2px solid black;
    color: black;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000000;
}

.fp-video {
    height: 280px;
    object-fit: cover;
    object-position: top;
}
</style>
